@import "../../../common/variable.module.scss";

.artist{

    .artist_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .artist_nav{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;

        .filter_search_btn {
            display: flex;
            justify-content: space-between;
    
            input,
            select{
                height: 4rem;
                border: none;
                border-radius: 0.5rem;
                padding: 0rem 2rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                cursor: pointer;
            }
    
            button {
                border: none;
                background-color: white;
                height: 4rem;
                padding: 0rem 2rem;
                border-radius: 0.5rem;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                transition: all 0.2s;
    
                svg {
                    font-size: 2rem;
                }
    
                &:hover {
                    background-color: $theme-dark-color;
                    background-color: rgb(233, 233, 233);
                }
            }
    
            .filter_sort{
                display: flex;
                justify-content: center;
                gap: 1rem;
    
                .filter{
                    position: relative;
    
                    .filter_dropdown{
                        width: 40rem;
                        position: absolute;
                        left: 0%;
                        top: 120%;
                        z-index: 10;
                        background-color: white;
                        border-radius: 1rem;
                        overflow: hidden;
                        box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;
                        padding-bottom: 1rem;
    
                        label{
                            font-size: 1.5rem;
                        }
    
                        .heading_clear{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
    
                            p{
                                font-size: 1.4rem;
    
                                &:first-child{
                                    color: #8d8d8d;
                                }
                                    
                                &:last-child{
                                    color: #002ee4;
                                    cursor: pointer;
                                }
                            }
                        }
    
                        .heading{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: #f9f9f9;
                            padding: 1rem 2rem;
                            border-bottom: 0.1rem solid #e7e7e7;
    
                            p{
                                font-size: 1.6rem;
                                font-weight: 500;
                            }
    
                            svg{
                                font-size: 1.8rem;
                                cursor: pointer;
                            }
                        }
    
                        // .selectDate{
                        //     margin-top: 2rem;
                        //     display: flex;
                        //     flex-direction: column;
                        //     gap: 1rem;
                        //     padding: 1rem 2rem;
    
                        //     .filter_date{
                        //         display: flex;
                        //         align-items: center;
                        //         justify-content: space-between;
    
                        //         .from,
                        //         .to{
                        //             display: flex;
                        //             flex-direction: column;
                        //             gap: 0.5rem;

                        //             input{
                        //                 cursor: pointer;
                        //             }
                        //         }
                        //     }
                        // }
    
                        .selectStatus{
                            margin-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            padding: 1rem 2rem;
                        }
    
                        .buttons{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 1rem 2rem;
    
                            
                                button{
                                    font-size: 1.4rem;
    
                                    &:last-child{
                                        background-color: #002ee4;
                                        color: white;
                                    }
                                }
                            
                        }
    
                    }
                }
    
                .sort{
                    position: relative;
    
                    .sort_dropdown{
                        width: 20rem;
                        position: absolute;
                        left: 0%;
                        top: 120%;
                        z-index: 10;
                        background-color: white;
                        border-radius: 1rem;
                        overflow: hidden;
                        box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;
                            
                        p{
                            font-size: 1.5rem;
                            display: flex;
                            justify-content: space-between;
                            padding: 0rem 2rem;
                            border-bottom: 0.1rem solid #e7e7e7;
                            line-height: 4rem;
                            transition: all 0.2s;
                            cursor: pointer;
    
                            &:hover{
                                background-color: #ececec;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
                
            }
    
            .search{
                align-items: center;
                input {
                    width: 35rem;
                }
            }
        }

        .artist_list{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            grid-gap: 5rem;
            justify-items: center;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // min-height: 11.9rem;
            // gap: 5rem;
            margin-top: 3rem;

            .dataNotFound{
                grid-column: 1/8;
                font-size: 1.8rem;
                color: #878787;
            }

            .artist_description {
                cursor: pointer;
                text-align: center;
            
                .artist_profile {
                    position: relative; 
                    overflow: hidden; 
                    display: inline-block;
                    border-radius: 50%;

                    img {
                        width: 16rem;
                        height: 16rem;
                        border-radius: 50%;
                        object-fit: cover;
                        transition: transform 0.3s ease;
                        display: block;
                        transition: all 1s;
            
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            
                h4 {
                    font-size: 2rem;
                    font-weight: 500; 
                }
            
                p {
                    font-size: 1.5rem;
                }
            }
            
        }
    }
}