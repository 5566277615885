@import "../../../common/variable.module.scss";

.channel{

    .channel_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .channelAdd{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;

        .form_heading{
            position: relative;
            height: 4rem;
    
            h3{
                font-size: 2rem;
                font-weight: 600;
                text-align: center;
                line-height: 4rem;
                color: $theme-dark-color;
            }
    
            button{
                position: absolute;
                left: 0%;
                top: 0%;
                border: none;
                background-color: white;
                height: 100%;
                padding: 0rem 2rem;
                border-radius: 0.5rem;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                transition: all 0.2s;
    
                svg {
                    font-size: 2rem;
                }
    
                &:hover {
                    background-color: rgb(233, 233, 233);
                }
            }
        }

        .add_form{
            margin-top: 2rem;
            margin-bottom: 2%;
            
            .form_grid{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;
    
                .form_field{
                    display: flex;
                    flex-direction: column;
    
                    label{
                        font-size: 1.6rem;
                        font-weight: 500;
                        line-height: 4.5rem;
                        padding-top: 2rem;
                    }
    
                    input, select, textarea{
                        width: 100%;
                        height: 5rem;
                        border-radius: 0.5rem;
                        border: 1px solid #e0e0e0;
                        font-size: 1.6rem;
                        padding: 0rem 2rem;
                        outline: none;
                        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
    
                        &:focus{
                            outline: 0.1rem solid $theme-dark-color;
                        }
                    }

                    textarea {
                        resize: none;
                        height: 10rem;
                        padding: 1rem 1.2rem;
            
                        @media (max-width: 768px) {
                          height: 8rem;
                          padding: 0.8rem 1rem;
                        }
                    }
    
                    .customFileInput {
                        position: relative;
                        width: 100%;
        
                        input[type="file"] {
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .buttons{
                margin-top: 5rem;
                display: flex;
                gap: 2rem;
                
                button{
                    padding: 0rem 2rem;
                    height: 5rem;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 1.8rem;
                    border-radius: 0.5rem;
                    background-color: white;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.3s;
                    border: 0.2rem solid transparent;

                    svg {
                        animation: loading 2s ease infinite;
            
                        @keyframes loading {
                          0% {
                            transform: rotate(0deg);
                          }
                          100% {
                            transform: rotate(360deg);
                          }
                        }
                    }
                    
                    &.action{
                        background-color: #01928d;
                        color: white;
                    }
    
    
                    &.cancel{
                        border: 0.1rem solid #01928d;
                    }
    
                    &:hover {
    
                        &.action{
                            background-color: #006e6b;
                        }
    
                        &.cancel{
                            background-color: rgb(245, 245, 245);
                        }
                    }
                }
            }
        }
    }
}