@import "../../../common/variable.module.scss";

.channel {
  .deleteArtist {
    position: fixed;
    z-index: 110;
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.705);
    display: flex;
    align-items: center;
    justify-content: center;

    .innerDeleteArtist {
      min-width: 35rem;
      max-width: 40rem;
      background-color: white;
      padding: 3rem 4rem;
      border-radius: 1rem;

      .heading {
        font-size: 2rem;
        p {
          text-align: center;
        }
      }

      .delete_image {
        height: 15rem;
        padding: 2rem;
        width: 100%;
        overflow: hidden;
        text-align: center;
        img {
          height: 100%;
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        button {
          border: none;
          padding: 1rem;
          border-radius: 0.5rem;
          cursor: pointer;

          &:first-child {
            background-color: #dc546a;
            color: white;
          }

          &:last-child {
            background-color: #5185ea;
            color: white;
          }
        }
      }
    }
  }

  .artist_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .channel_nav {
    width: 100%;
    background-color: #ffffff;
    border-radius: 1rem;
    min-height: 100%;
    padding: 2rem;

    .artist {
      display: flex;
      justify-content: space-between;

      .channel_info {
        .channel_navigate {
          position: relative;
          left: 0%;
          top: 0%;

          button {
            border: none;
            color: #818182;
            background-color: transparent;
            // height: 100%;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            svg {
              font-size: 2rem;
            }
          }
        }

        .channel_details {
          position: relative;
          left: 0%;
          top: 0%;
          margin-top: 4rem;
          padding: 0rem 0.5rem;

          p {
            font-size: 1.6rem;
            color: #818182;
          }

          .channel_name_status {
            display: flex;
            gap: 2rem;
            align-items: baseline;

            h2 {
              font-size: 4rem;
              font-weight: 600;
              color: black;
              padding-top: 1rem;
            }

            .part {
              svg {
                font-size: 1.6rem;
                color: #818182;
              }
            }
          }
        }

        .channel_counts {
          position: relative;
          left: 0%;
          top: 0%;
          display: flex;
          color: #818182;
          align-items: end;
          gap: 1rem;
          margin-top: 1rem;
          padding: 0rem 0.5rem;

          .channel_songs {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            p,
            svg {
              font-size: 1.6rem;
              color: #818182;
            }

            p:last-child {
              color: black;
            }
          }

          .part {
            svg {
              font-size: 1.6rem;
              color: #818182;
            }
          }
        }

        .buttons {
          display: flex;
          gap: 1rem;

          margin-top: 2rem;

          .active,
          .delete {
            border: none;
            background-color: white;
            height: 4rem;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;

              &.activeSvg {
                color: green;
              }

              &.deactiveSvg {
                color: red;
              }

              &.deleteSvg {
                color: red;
              }
            }

            &:hover {
              background-color: $theme-dark-color;
              background-color: rgb(233, 233, 233);
            }
          }

          .active_btn,
          .approve_btn,
          .delete_btn {
            border: none;
            background-color: transparent;
            font-size: 1.5rem;
            cursor: pointer;
          }
        }
      }

      .channel_image {
        width: 40rem;
        height: 20rem;
        border-radius: 0.5rem;
        overflow: hidden;
        margin-top: 3rem;
        box-shadow: $box-shadow;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .channel_data_buttons {
    background-color: transparent;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;
    padding: 1rem 0rem;

    .buttons {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      height: 100%;
      padding: 1rem 2rem;
      background-color: #ffffff;
      border-radius: 0.5rem;

      button {
        border: none;
        background-color: transparent;
        border-radius: 0.5rem;
        height: 4rem;
        font-size: 1.6rem;
        padding: 0rem 2rem;
        cursor: pointer;
        transition: background-color 0.5s;

        &:hover {
          background-color: #cacaca;
        }

        &.active {
          color: rgb(255, 255, 255);
          background-color: #424242;
        }
      }
    }
  }

  .channel_track_data {
    width: 100%;
    float: left;
    margin-top: 2rem;
    padding: 2rem 2rem;
    background-color: white;
    border-radius: 0.5rem;

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0rem 0rem 2rem 0rem;

      input,
      select {
        height: 4rem;
        width: 35rem;
        border: none;
        border-radius: 0.5rem;
        padding: 0rem 2rem;
        outline: none;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        cursor: pointer;
      }

      button {
        border: none;
        background-color: white;
        height: 4rem;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: $theme-dark-color;
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .channel_data_table {
      // margin-top: 1.5rem;
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        align-items: center;

        th {
          color: $theme-text-color;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1.2rem 3rem;
          text-align: left;
          color: #878787;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          background-color: rgb(240, 240, 240);
        }

        tbody {
          .dataNotFound {
            text-align: center;
            width: 100%;
            font-size: 1.8rem;
            color: #878787;
          }

          tr {
            border-bottom: 0.1rem solid #e1e1e1;

            &:nth-child(even) {
              background-color: #efefef;
            }

            &:last-child {
              border-bottom: none;

              .manageCategory {
                bottom: 100%;
              }
            }

            td {
              padding: 2.2rem 3rem;
              font-size: 1.4rem;
              color: #323643;

              &.play,
              &.time {
                .play_div,
                .time_div {
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                }
              }

              &.name_image {
                display: flex;
                align-items: center;
                gap: 1rem;

                p {
                  width: 40rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              div {
                &.track_image {
                  // background-color: red;
                  width: 3rem;
                  height: 3rem;
                  border-radius: 0.5rem;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }

              svg {
                font-size: 1.8rem;
              }
            }

            .track_btn {
              display: flex;
              // justify-content: center;

              td {
                padding: 2.2rem 1rem !important ;
              }

              .edit_track {
                svg {
                  color: #3e85c3;
                  // color: #53bcdc;
                  cursor: pointer;
                }
              }

              .delete_track {
                svg {
                  color: red;
                  cursor: pointer;
                }
              }
            }

            .edit_language {
              cursor: pointer;
            }
          }
        }
      }
    }

    .add_normal_track {
      padding: 0rem 2rem;

      .track_heading {
        margin-bottom: 2rem;

        h2 {
          font-size: 2rem;
          font-weight: 500;
          color: rgb(97, 97, 97);
        }
      }

      .artistNames {
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          font-size: 1.6rem;

          span {
            margin-left: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
              color: red;
            }
          }
        }
      }

      .trackAdd {
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 0rem;

        .form_heading {
          position: relative;
          height: 4rem;

          h3 {
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 4rem;
            color: $theme-dark-color;
          }

          button {
            position: absolute;
            left: 0%;
            top: 0%;
            border: none;
            background-color: white;
            height: 100%;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;
            }

            &:hover {
              background-color: rgb(233, 233, 233);
            }
          }
        }

        .add_form {
          margin-top: 2rem;
          margin-bottom: 2%;

          .form_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .form_field {
              display: flex;
              flex-direction: column;

              &.searchData {
                background-color: white;
                position: relative;

                .artistListHere {
                  position: absolute;
                  border-radius: 0.5rem;
                  height: 20rem;
                  width: 100%;
                  top: 105%;
                  overflow: auto;
                  box-shadow: $box-shadow;
                  background-color: white;
                  z-index: 1;

                  &::-webkit-scrollbar {
                    width: 0.5rem;
                    background-color: transparent;
                  }

                  &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: white;
                  }

                  .artistList {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: rgb(224, 224, 224);
                    padding: 0.5rem 2rem;
                    border-bottom: 0.1rem solid grey;
                    font-size: 1.6rem;
                    h4 {
                      font-weight: 500;
                    }
                  }

                  .listData {
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    display: flex;
                    // justify-content: space-between;
                    gap: 2rem;
                    border-bottom: 0.1rem solid rgb(196, 196, 196);

                    &:last-child {
                      border-bottom: none;
                    }

                    button {
                      background-color: green;
                      color: white;
                      padding: 0.2rem 2rem;
                      border: none;
                      border-radius: 0.5rem;
                      cursor: pointer;
                    }
                  }
                }
              }

              label {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 4.5rem;
                padding-top: 2rem;
              }

              input:not(.checkbox),
              select,
              textarea {
                width: 100%;
                height: 5rem;
                border-radius: 0.5rem;
                border: 1px solid #e0e0e0;
                font-size: 1.6rem;
                padding: 0rem 2rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

                &:focus {
                  outline: 0.1rem solid $theme-dark-color;
                }
              }

              textarea {
                resize: none;
                height: 10rem;
                padding: 1rem 1.2rem;
              }

              .customFileInput {
                position: relative;
                width: 100%;

                input[type="file"] {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                }
              }
            }
          }

          .buttons {
            margin-top: 5rem;
            display: flex;
            gap: 2rem;

            button {
              padding: 0rem 2rem;
              height: 5rem;
              cursor: pointer;
              font-weight: 500;
              font-size: 1.8rem;
              border-radius: 0.5rem;
              background-color: white;
              box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
              transition: all 0.3s;
              border: 0.2rem solid transparent;

              &.action {
                background-color: #01928d;
                color: white;

                svg {
                  font-size: 2rem;
                  animation: loading 2s ease infinite;
                  @keyframes loading {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }

              &.cancel {
                border: 0.1rem solid #01928d;
              }

              &:hover {
                &.action {
                  background-color: #006e6b;
                }

                &.cancel {
                  background-color: rgb(245, 245, 245);
                }
              }
            }
          }
        }
      }
    }
  }

  .channel_playlist_data {
    width: 100%;
    float: left;
    margin-top: 2rem;
    padding: 2rem 2rem;
    background-color: white;
    border-radius: 0.5rem;

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0rem 0rem 2rem 0rem;

      input,
      select {
        height: 4rem;
        width: 35rem;
        border: none;
        border-radius: 0.5rem;
        padding: 0rem 2rem;
        outline: none;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        cursor: pointer;
      }

      button {
        border: none;
        background-color: white;
        height: 4rem;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: $theme-dark-color;
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .channel_playlist_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1.7rem;
      width: 100%;

      .dataNotFound {
        width: 100%;
        color: #878787;
        font-size: 1.8rem;
        text-align: center;
      }

      .playlistCard {
        width: 28rem;
        margin: 1rem;
        border-radius: 1rem;
        // overflow: hidden;
        cursor: pointer;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

        .image {
          // height: 20rem;
          // width: 100%;
          // border-radius: 0.5rem;
          // overflow: hidden;

          img {
            width: 100%;
            height: 20rem;
            object-fit: cover;
            border-radius: 1rem 1rem 0rem 0rem;
          }
        }

        .playlist_details {
          padding: 1rem;

          h3 {
            font-size: 1.6rem;
            font-weight: 500;
          }

          p {
            font-size: 1.4rem;
            color: #666666;
          }
        }
      }
    }

    .add_normal_playlist {
      padding: 0rem 2rem;

      .playlist_heading {
        margin-bottom: 2rem;

        h2 {
          font-size: 2rem;
          font-weight: 500;
          color: rgb(97, 97, 97);
        }
      }

      .playlistAdd {
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 0rem;

        .form_heading {
          position: relative;
          height: 4rem;

          h3 {
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 4rem;
            color: $theme-dark-color;
          }

          button {
            position: absolute;
            left: 0%;
            top: 0%;
            border: none;
            background-color: white;
            height: 100%;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;
            }

            &:hover {
              background-color: rgb(233, 233, 233);
            }
          }
        }

        .add_form {
          margin-top: 2rem;
          margin-bottom: 2%;

          .form_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .form_field {
              display: flex;
              flex-direction: column;

              &.searchData {
                background-color: white;
                position: relative;

                .artistListHere {
                  position: absolute;
                  border-radius: 0.5rem;
                  height: 20rem;
                  width: 100%;
                  top: 105%;
                  overflow: auto;
                  box-shadow: $box-shadow;
                  background-color: white;

                  .artistList {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: rgb(224, 224, 224);
                    padding: 0.5rem 2rem;
                    border-bottom: 0.1rem solid grey;
                    font-size: 1.6rem;
                    h4 {
                      font-weight: 500;
                    }
                  }

                  .listData {
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    display: flex;
                    // justify-content: space-between;
                    gap: 2rem;
                    border-bottom: 0.1rem solid rgb(196, 196, 196);

                    &:last-child {
                      border-bottom: none;
                    }

                    button {
                      background-color: green;
                      color: white;
                      padding: 0.2rem 2rem;
                      border: none;
                      border-radius: 0.5rem;
                      cursor: pointer;
                    }
                  }
                }
              }

              label {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 4.5rem;
                padding-top: 2rem;
              }

              input:not(.checkbox),
              select,
              textarea {
                width: 100%;
                height: 5rem;
                border-radius: 0.5rem;
                border: 1px solid #e0e0e0;
                font-size: 1.6rem;
                padding: 0rem 2rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

                &:focus {
                  outline: 0.1rem solid $theme-dark-color;
                }
              }

              textarea {
                resize: none;
                height: 10rem;
                padding: 1rem 1.2rem;
              }

              .customFileInput {
                position: relative;
                width: 100%;

                input[type="file"] {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                }
              }
            }
          }

          .buttons {
            margin-top: 5rem;
            display: flex;
            gap: 2rem;

            button {
              padding: 0rem 2rem;
              height: 5rem;
              cursor: pointer;
              font-weight: 500;
              font-size: 1.8rem;
              border-radius: 0.5rem;
              background-color: white;
              box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
              transition: all 0.3s;
              border: 0.2rem solid transparent;

              &.action {
                background-color: #01928d;
                color: white;

                svg {
                  font-size: 2rem;
                  animation: loading 2s ease infinite;
                  @keyframes loading {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }

              &.cancel {
                border: 0.1rem solid #01928d;
              }

              &:hover {
                &.action {
                  background-color: #006e6b;
                }

                &.cancel {
                  background-color: rgb(245, 245, 245);
                }
              }
            }
          }
        }
      }
    }
  }

  .channel_show_data {
    width: 100%;
    float: left;
    margin-top: 2rem;
    padding: 2rem 2rem;
    background-color: white;
    border-radius: 0.5rem;

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0rem 0rem 2rem 0rem;

      input,
      select {
        height: 4rem;
        width: 35rem;
        border: none;
        border-radius: 0.5rem;
        padding: 0rem 2rem;
        outline: none;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        cursor: pointer;
      }

      button {
        border: none;
        background-color: white;
        height: 4rem;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: $theme-dark-color;
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .channel_show_list {
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        align-items: center;

        th {
          color: $theme-text-color;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1.2rem 3rem;
          text-align: left;
          color: #878787;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          background-color: rgb(240, 240, 240);
        }

        tbody {
          .dataNotFound {
            text-align: center;
            width: 100%;
            font-size: 1.8rem;
            color: #878787;
          }

          tr {
            border-bottom: 0.1rem solid #e1e1e1;

            &:nth-child(even) {
              background-color: #efefef;
            }

            &:last-child {
              border-bottom: none;

              .manageCategory {
                bottom: 100%;
              }
            }

            td {
              padding: 2.2rem 3rem;
              font-size: 1.4rem;
              color: #323643;

              &.play,
              &.time {
                .play_div,
                .time_div {
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                }
              }

              &.name_image {
                display: flex;
                align-items: center;
                gap: 1rem;

                p {
                  width: 40rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.delete_show {
                svg {
                  color: red;
                  cursor: pointer;
                }
              }

              div {
                &.show_image {
                  // background-color: red;
                  width: 3rem;
                  height: 3rem;
                  border-radius: 0.5rem;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }

              svg {
                font-size: 1.8rem;
              }
            }

            .edit_language {
              cursor: pointer;
            }
          }
        }
      }
    }

    .add_normal_show {
      padding: 0rem 2rem;

      .show_heading {
        margin-bottom: 2rem;

        h2 {
          font-size: 2rem;
          font-weight: 500;
          color: rgb(97, 97, 97);
        }
      }

      .showAdd {
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 0rem;

        .form_heading {
          position: relative;
          height: 4rem;

          h3 {
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 4rem;
            color: $theme-dark-color;
          }

          button {
            position: absolute;
            left: 0%;
            top: 0%;
            border: none;
            background-color: white;
            height: 100%;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;
            }

            &:hover {
              background-color: rgb(233, 233, 233);
            }
          }
        }

        .add_form {
          margin-top: 2rem;
          margin-bottom: 2%;

          .form_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .form_field {
              display: flex;
              flex-direction: column;

              &.searchData {
                background-color: white;
                position: relative;

                .artistListHere {
                  position: absolute;
                  border-radius: 0.5rem;
                  height: 20rem;
                  width: 100%;
                  top: 105%;
                  overflow: auto;
                  box-shadow: $box-shadow;
                  background-color: white;

                  .artistList {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: rgb(224, 224, 224);
                    padding: 0.5rem 2rem;
                    border-bottom: 0.1rem solid grey;
                    font-size: 1.6rem;
                    h4 {
                      font-weight: 500;
                    }
                  }

                  .listData {
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    display: flex;
                    // justify-content: space-between;
                    gap: 2rem;
                    border-bottom: 0.1rem solid rgb(196, 196, 196);

                    &:last-child {
                      border-bottom: none;
                    }

                    button {
                      background-color: green;
                      color: white;
                      padding: 0.2rem 2rem;
                      border: none;
                      border-radius: 0.5rem;
                      cursor: pointer;
                    }
                  }
                }
              }

              label {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 4.5rem;
                padding-top: 2rem;
              }

              input:not(.checkbox),
              select,
              textarea {
                width: 100%;
                height: 5rem;
                border-radius: 0.5rem;
                border: 1px solid #e0e0e0;
                font-size: 1.6rem;
                padding: 0rem 2rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

                &:focus {
                  outline: 0.1rem solid $theme-dark-color;
                }
              }

              textarea {
                resize: none;
                height: 10rem;
                padding: 1rem 1.2rem;
              }

              .customFileInput {
                position: relative;
                width: 100%;

                input[type="file"] {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                }
              }
            }
          }

          .buttons {
            margin-top: 5rem;
            display: flex;
            gap: 2rem;

            button {
              padding: 0rem 2rem;
              height: 5rem;
              cursor: pointer;
              font-weight: 500;
              font-size: 1.8rem;
              border-radius: 0.5rem;
              background-color: white;
              box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
              transition: all 0.3s;
              border: 0.2rem solid transparent;

              &.action {
                background-color: #01928d;
                color: white;

                svg {
                  font-size: 2rem;
                  animation: loading 2s ease infinite;
                  @keyframes loading {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }

              &.cancel {
                border: 0.1rem solid #01928d;
              }

              &:hover {
                &.action {
                  background-color: #006e6b;
                }

                &.cancel {
                  background-color: rgb(245, 245, 245);
                }
              }
            }
          }
        }
      }
    }
  }
}
