@import "../../../common/variable.module.scss";

.work {
  .work_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);

      @media (max-width: 1024px) {
        font-size: 1.8rem;
      }

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }

  .work_nav {
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    min-height: 100%;
    padding: 4rem;

    @media (max-width: 1024px) {
      padding: 3rem;
    }

    @media (max-width: 768px) {
      padding: 2rem;
    }

    .form_heading {
      position: relative;
      height: 4rem;

      h3 {
        font-size: 3rem;
        font-weight: 600;
        text-align: center;
        line-height: 4rem;
        color: $theme-dark-color;

        @media (max-width: 1024px) {
          font-size: 2.5rem;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      button {
        position: absolute;
        left: 0%;
        top: 0%;
        border: none;
        background-color: white;
        height: 100%;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        @media (max-width: 1024px) {
          font-size: 1.4rem;
        }

        @media (max-width: 768px) {
          font-size: 1.2rem;
          padding: 0 1.5rem;
        }

        svg {
          font-size: 2rem;

          @media (max-width: 768px) {
            font-size: 1.6rem;
          }
        }

        &:hover {
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .add_form {
      margin-top: 2rem;
      margin-bottom: 2%;

      .social_media,
      .company_details {
        h3 {
          font-size: 2rem;
          font-weight: 600;
          margin-top: 7rem;
          line-height: 0.5rem;
          color: $theme-dark-color;

          @media (max-width: 768px) {
            font-size: 1.8rem;
            margin-top: 5rem;
          }
        }
      }

      .form_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        @media (max-width: 1024px) {
          grid-template-columns: 1fr;
        }

        .form_field {
          display: flex;
          flex-direction: column;

          label {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 4.5rem;
            padding-top: 2rem;

            @media (max-width: 1024px) {
              font-size: 1.5rem;
            }

            @media (max-width: 768px) {
              font-size: 1.4rem;
              padding-top: 1rem;
            }
          }

          input,
          textarea {
            width: 100%;
            height: 5rem;
            border-radius: 0.5rem;
            border: 1px solid #e0e0e0;
            font-size: 1.6rem;
            padding: 0rem 2rem;
            outline: none;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

            @media (max-width: 1024px) {
              height: 4.5rem;
              font-size: 1.5rem;
            }

            @media (max-width: 768px) {
              height: 4rem;
              font-size: 1.4rem;
              padding: 0rem 1.5rem;
            }

            &:focus {
              outline: 0.1rem solid $theme-dark-color;
            }
          }

          textarea {
            resize: none;
            height: 10rem;
            padding: 1rem 1.2rem;

            @media (max-width: 768px) {
              height: 8rem;
              padding: 0.8rem 1rem;
            }
          }
        }
      }

      .button {
        margin-top: 5rem;
        display: flex;
        gap: 2rem;

        @media (max-width: 768px) {
          margin-top: 4rem;
          flex-direction: column;
        }

        button {
          padding: 0rem 2rem;
          height: 5rem;
          cursor: pointer;
          font-weight: 500;
          font-size: 1.8rem;
          border-radius: 0.5rem;
          background-color: white;
          box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
          transition: all 0.3s;
          border: 0.2rem solid transparent;

          @media (max-width: 768px) {
            font-size: 1.6rem;
            padding: 0 1.5rem;
          }

          svg {
            animation: loading 2s ease infinite;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }

          &.action {
            background-color: #01928d;
            color: white;
          }

          &:hover {
            &.action {
              background-color: #006e6b;
            }
          }
        }
      }
    }
  }
}
