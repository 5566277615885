@import "../../common/variable.module.scss";

.loginMain {
  background-color: $theme-dark-color;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loginBg {
    background-color: $theme-light-color;
    min-width: 50rem;
    max-width: 50rem;
    width: fit-content;
    padding: 3rem 4rem;
    border-radius: 1rem;

    @media (max-width: 1024px) {
      min-width: 45rem;
      padding: 3rem;
    }

    @media (max-width: 768px) {
      min-width: 35rem;
      padding: 2rem;
    }

    .logo {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      justify-content: center;

      img {
        width: 40%;

        @media (max-width: 1024px) {
          width: 35%;
        }

        @media (max-width: 768px) {
          width: 30%;
        }
      }

      h3 {
        font-size: 3rem;
        color: white;
        font-weight: 600;

        @media (max-width: 1024px) {
          font-size: 2.5rem;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 3rem;

      @media (max-width: 1024px) {
        gap: 2.5rem;
        margin-top: 3.5rem;
      }

      @media (max-width: 768px) {
        gap: 2rem;
        margin-top: 3rem;
      }

      .inputField {
        div {
          position: relative;

          svg {
            position: absolute;
            font-size: 2rem;
            right: 1.5rem;
            color: white;
            cursor: pointer;
            z-index: 2;
            bottom: 50%;
            transform: translateY(80%);

            @media (max-width: 1024px) {
              font-size: 1.8rem;
              right: 1.2rem;
            }

            @media (max-width: 768px) {
              font-size: 1.5rem;
              right: 1rem;
            }
          }
        }

        label {
          color: white;
          font-size: 1.8rem;

          @media (max-width: 1024px) {
            font-size: 1.6rem;
          }

          @media (max-width: 768px) {
            font-size: 1.4rem;
          }
        }

        input {
          margin-top: 1rem;
          font-size: 1.6rem;
          padding: 1.2rem 2rem;
          border: 0.1rem solid #939393;
          border-radius: 1rem;
          background-color: transparent;
          outline: none;
          width: 100%;
          color: white;

          @media (max-width: 1024px) {
            padding: 1rem 1.8rem;
            font-size: 1.5rem;
          }

          @media (max-width: 768px) {
            padding: 1rem 1.5rem;
            font-size: 1.4rem;
          }
        }

        p {
          color: red;
          margin-left: 1rem;
          margin-top: 1rem;
          font-size: 1.2rem;
          position: relative;

          @media (max-width: 1024px) {
            font-size: 1.1rem;
            margin-left: 0.8rem;
          }

          @media (max-width: 768px) {
            font-size: 1rem;
            margin-left: 0.5rem;
          }
        }
      }

      .forgotPassword {
        font-size: 1.6rem;
        text-align: right;

        @media (max-width: 1024px) {
          font-size: 1.5rem;
        }

        @media (max-width: 768px) {
          font-size: 1.4rem;
        }

        a {
          font-weight: 400;
          text-decoration: underline;
          color: white;
        }
      }

      .button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        @media (max-width: 1024px) {
          gap: 2.5rem;
        }

        @media (max-width: 768px) {
          gap: 2rem;
        }

        button {
          width: 100%;
          padding: 1rem 0rem;
          border: none;
          font-size: 1.8rem;
          font-weight: 600;
          border-radius: 1rem;
          background-color: white;
          cursor: pointer;

          @media (max-width: 1024px) {
            font-size: 1.7rem;
            padding: 1.2rem 0;
          }

          @media (max-width: 768px) {
            font-size: 1.6rem;
            padding: 1rem 0;
          }

          svg {
            animation: loading 2s ease infinite;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }
}
