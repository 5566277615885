@import "../../../common/variable.module.scss";

.user {

    .user_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .user_view{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;

        .detail_heading{
            position: relative;
            height: 4rem;
    
            h3{
                font-size: 2rem;
                font-weight: 600;
                text-align: center;
                line-height: 4rem;
                color: $theme-dark-color;
            }
    
            button{
                position: absolute;
                left: 0%;
                top: 0%;
                border: none;
                background-color: white;
                height: 100%;
                padding: 0rem 2rem;
                border-radius: 0.5rem;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                transition: all 0.2s;
    
                svg {
                    font-size: 2rem;
                }
    
                &:hover {
                    background-color: rgb(233, 233, 233);
                }
            }
        }

        .details{
            margin-top: 2rem;
            border-radius: 0.5rem;
            background-color: #EFF3F6;

            display: flex;
            justify-content: space-between;

            .img_name{
                padding: 4rem;
                display: flex;
                align-items: center;
                gap: 2rem;

                .user_profile{
                    width: 10rem;
                    height: 10rem;
                    overflow: hidden;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        height: 100%;
                        width: auto;
                    }
                }

                .user_name{
                    h3{
                        font-size: 2.5rem;
                        font-weight: 500;
                    }

                    p{
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}