@import '../common/variable.module.scss';

.navbar {
    background-color: $navbar-bg-color;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.5rem rgba(128, 128, 128, 0.15);
    width: calc(100% - $navbar-toggle-on-width-margin);
    margin-left: $navbar-toggle-on-width-margin;
    height: 60px;
    transition: all 0.3s;
    z-index: 101;
    position: relative;

    @media (max-width: 1280px) {
      width: 100%;
      margin-left: 0%;
    }

    // &.active{
    //   width: calc(100% - $navbar-toggle-off-width-margin);
    //   margin-left: $navbar-toggle-off-width-margin;
    // }
  
    .nav {
      width: 96% !important;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      &.container {
        width: 1000px;
        margin: auto;
      }
  
      svg {
        font-size: 2rem;
        cursor: pointer;
        color: black;
      }
  
      .toggle {
        
        svg {
          display: none;
          font-size: 2rem;
          cursor: pointer;

          @media (max-width: 1280px){
            display: flex;
          }
        }

      }
  
      .menu {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 3rem;
  
        .profile {
          position: relative;
          display: flex;
          cursor: pointer;
  
          &.open .profile_dropdown {
            display: block;
          }
  
          &:hover .profile_dropdown {
            display: block;
          }
  
          .profile_photo_name {
            display: flex;
            align-items: center;
            gap: 1rem;
  
            .user_logo {
              height: 3rem;
              width: 3rem;
              border-radius: 50%;
              background-color: red;
  
              img {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
  
            p {
              font-size: 1.6rem;
              font-weight: 500;
              color: black;
            }
          }
  
          .profile_dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            display: none;
            background-color: white;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
            z-index: 10;
            border-radius: 1rem;
  
            a {
              display: flex;
              align-items: center;
              padding: 1rem 2.5rem;
              gap: 1rem;
              color: #333;
              text-decoration: none;
              font-size: 1.6rem;
  
              svg {
                margin-right: 0.5rem;
              }
  
              &:hover {
                background-color: #f0f0f0;
              }
            }
  
            hr {
              margin: 0.5rem 0;
              border: none;
              border-top: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
  