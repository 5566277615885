@import "../../../common/variable.module.scss";

.artist {
  .deleteArtist {
    position: fixed;
    z-index: 110;
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.705);
    display: flex;
    align-items: center;
    justify-content: center;

    .innerDeleteArtist {
      min-width: 35rem;
      max-width: 40rem;
      background-color: white;
      padding: 3rem 4rem;
      border-radius: 1rem;

      .heading {
        font-size: 2rem;
        p {
          text-align: center;
        }
      }

      .delete_image {
        height: 15rem;
        padding: 2rem;
        width: 100%;
        overflow: hidden;
        text-align: center;
        img {
          height: 100%;
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        button {
          border: none;
          padding: 1rem;
          border-radius: 0.5rem;
          cursor: pointer;

          &:first-child {
            background-color: #dc546a;
            color: white;
          }

          &:last-child {
            background-color: #5185ea;
            color: white;
          }
        }
      }
    }
  }

  .artist_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .artist_nav {
    width: 100%;
    background-color: #ffffff;
    border-radius: 1rem;
    min-height: 100%;
    padding: 2rem;

    .artist {
      display: flex;
      justify-content: space-between;

      .artist_info {
        .artist_navigate {
          position: relative;
          left: 0%;
          top: 0%;

          button {
            border: none;
            color: #818182;
            background-color: transparent;
            // height: 100%;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            svg {
              font-size: 2rem;
            }
          }
        }

        .artist_details {
          position: relative;
          left: 0%;
          top: 0%;
          margin-top: 4rem;
          padding: 0rem 0.5rem;

          p {
            font-size: 1.6rem;
            color: #818182;
          }

          .artist_name_status {
            display: flex;
            gap: 2rem;
            align-items: baseline;

            h2 {
              width: 33rem;
              font-size: 4rem;
              font-weight: 600;
              color: black;
              padding-top: 1rem;
            }

            .part {
              svg {
                font-size: 1.6rem;
                color: #818182;
              }
            }
          }
        }

        .artist_counts {
          position: relative;
          left: 0%;
          top: 0%;
          display: flex;
          color: #818182;
          align-items: end;
          gap: 1rem;
          margin-top: 1rem;
          padding: 0rem 0.5rem;

          .artist_albums,
          .artist_songs {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            p,
            svg {
              font-size: 1.6rem;
              color: #818182;
            }

            p:last-child {
              color: black;
            }
          }

          .part {
            svg {
              font-size: 1.6rem;
              color: #818182;
            }
          }
        }

        .buttons {
          display: flex;
          gap: 1rem;

          margin-top: 2rem;

          .active,
          .approve,
          .delete {
            border: none;
            background-color: white;
            height: 4rem;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;

              &.approveSvg,
              &.activeSvg {
                color: green;
              }

              &.pendingSvg {
                color: rgb(177, 100, 0);
              }

              &.deactiveSvg,
              &.deleteSvg {
                color: red;
              }
            }

            &:hover {
              background-color: $theme-dark-color;
              background-color: rgb(233, 233, 233);
            }
          }

          .active_btn,
          .approve_btn,
          .delete_btn {
            border: none;
            background-color: transparent;
            font-size: 1.5rem;
            cursor: pointer;
          }
        }

        .artist_personal_details {
          margin-top: 3rem;

          background-color: white;
          padding: 0rem 2rem;
          border-radius: 0.5rem;
          font-size: 1.5rem;
          display: flex;
          flex-direction: column;
          // gap: 1rem;
          // cursor: pointer;
          box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
          transition: all 0.2s;

          .personal_info {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            padding: 1rem 0.5rem;
            align-items: center;

            .artist_stageName,
            .artist_dob,
            .artist_phoneNumber,
            .artist_nationality {
              display: flex;
              align-items: baseline;
              gap: 1rem;
              color: #818182;
              width: 25rem;

              p {
                color: black;
              }
            }
          }
        }

        // .play_tracks{
        //     position: relative;
        //     bottom: 0;
        //     left: 0%;
        //     top: 0%;
        //     display: flex;
        //     align-items: center;
        //     gap: 1rem;
        //     margin-top: 3rem;
        //     padding: 0rem 0.5rem;

        //     .play_button{
        //         background-color: black;
        //         border: none;
        //         border-radius: 50%;
        //         color: white;
        //         cursor: pointer;
        //         font-size: 2.4rem;
        //         height: 6rem;
        //         text-align: center;
        //         width: 6rem;
        //         margin-right: 1rem;

        //         padding-left: 0.5rem;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //     }

        //     .play_all_tracks{
        //         font-size: 1.6rem;
        //         font-weight: 600;
        //         color: black;
        //     }
        // }
      }

      .artist_image {
        position: relative;
        top: 0%;
        left: 0%;
        margin-top: 3rem;

        img {
          width: 28rem;
          height: 28rem;
          object-fit: cover;
          border-radius: 0.5rem;
          box-shadow: $box-shadow;

          @media (max-width: 1024px) {
            width: 25rem;
            height: 25rem;
          }
        }
      }
    }

    .artist_track,
    .artist_album,
    .artist_playlist {
      overflow: hidden;
      margin-top: 5rem;

      .track_heading,
      .album_heading,
      .playlist_heading {
        h2 {
          font-size: 2.8rem;
          font-weight: 600;
          color: black;
        }
      }

      .track_info,
      .album_info,
      .playlist_info {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 4rem;
        overflow: overlay;

        &::-webkit-scrollbar {
          display: none;
          // width: 0.1rem;
          // background-color: transparent;
        }

        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        //   background-color: white;
        // }

        .dataNotFound {
          grid-column: 9/1;
          font-size: 1.8rem;
          color: #878787;
          text-align: center;
        }

        .track_content,
        .album_content,
        .playlist_content {
          margin-top: 2rem;
          border-radius: 0.5rem;

          .track_image,
          .album_image,
          .playlist_image {
            width: 100%;
            height: 20rem;

            img {
              border-radius: 0.5rem;
              width: 100%;
              height: 100%;
              object-fit: cover;
              // cursor: pointer;
              box-shadow: $box-shadow;
            }
          }

          p {
            color: black;
            font-size: 2rem;
            font-weight: 500;

            &.title {
              margin-top: 2rem;

              width: 25rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.description {
              color: #818182;
              font-size: 1.6rem;

              width: 18rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
