// Role Screen Start ***********************
$role-bg-color: green;
$select-border-color: black;
$box-shadow: 0.1rem 0.1rem 0.5rem 0.5rem rgba(128, 128, 128, 0.151);
// Role Screen End ***********************


// Theme Color Start **************
$theme-dark-color: #23252c;
$theme-light-color: #1E1E1E;
$theme-text-color: #323643;

$box-bg-color: white;
$box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
// Theme Color End **************


// Sidebar Start ****************
$sidebar-bg-color: #23252c;
$sidebar-logo-bg-color: #23252c;
// ######
$sidebar-font-size: 1.6rem;
$sidebar-font-weight: 500;
$sidebar-font-color: white;
// ######
$sidebar-width: 24rem;
$sidebar-icon-size: 2rem;
$sidebar-active-color: rgba(255, 255, 255, 0.15);
$sidebar-hover-bg-color: #cfcfcf;
$sidebar-hover-font-color: #323643;
// Sidebar End ****************


// Navbar Start **************
$navbar-bg-color: #FFFFFF;
$navbar-height: 6rem;

$navbar-toggle-on-width-margin: $sidebar-width;
$navbar-toggle-off-width-margin: 0rem;
// Navbar End **************


// Content Start **************
// $navbar-bg-color: #FFFFFF;
// $navbar-height: 6rem;

$content-toggle-on-width-margin: $sidebar-width;
$content-toggle-off-width-margin: 0rem;
// Content End **************


// Container Start **************
$container-width: 96%;
// Container End **************


// Global Start **************
$hr-border-bottom: 0.1rem solid rgb(242 242 242 / 0.39);
// Global End **************

// Form Heading Button Start **************
$back-button-font-size: 1.5rem;
$back-button-border: none;
$back-button-bg-color: white;
$back-button-box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
$back-button-hover-bg-color: rgb(233, 233, 233);
// Form Heading Button End **************

// Update&Cancel Start **************
$action-button-bg-color: #01928d;
$action-button-color: white;
$action-button-hover-bg-color: #006e6b;

$cancel-button-border: 0.1rem solid #01928d;
$cancel-button-hover-bg-color: rgb(245, 245, 245);
// Update&Cancel End **************

// Pagination Start **************
// $active-pagination-background-color: #002ee4;
$active-pagination-background-color: black;
// Pagination End **************


$data-not-found-font-size: 1.6rem;
$data-not-found-font-color: grey;
