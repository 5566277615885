@import "../../../common/variable.module.scss";

.pp {

    .pp_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .pp_description{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;

        .pp_edit{

            .form_heading{
                position: relative;
                height: 4rem;
        
                h3{
                    font-size: 2rem;
                    font-weight: 600;
                    text-align: center;
                    line-height: 4rem;
                    color: $theme-dark-color;
                }
        
                button{
                    position: absolute;
                    left: 0%;
                    top: 0%;
                    border: none;
                    background-color: white;
                    height: 100%;
                    padding: 0rem 2rem;
                    border-radius: 0.5rem;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    cursor: pointer;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.2s;
        
                    svg {
                        font-size: 2rem;
                    }
        
                    &:hover {
                        background-color: rgb(233, 233, 233);
                    }
                }
            }

            .edit_list{
                margin-top: 2rem;
                margin-bottom: 2%;
                
                .list_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2rem;
        
                    .list_field{
                        display: flex;
                        flex-direction: column;
        
                        label{
                            font-size: 1.6rem;
                            font-weight: 500;
                            line-height: 4.5rem;
                            padding-top: 2rem;
                        }
        
                        input, textarea, select{
                            width: 100%;
                            height: 5rem;
                            border-radius: 0.5rem;
                            border: 1px solid #e0e0e0;
                            font-size: 1.6rem;
                            padding: 0rem 2rem;
                            outline: none;
                            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        
                            &:focus{
                                outline: 0.1rem solid $theme-dark-color;
                            }
        
                        }
        
                        textarea{
                            resize: none;
                            height: 10rem;
                            padding: 1rem 1.2rem;
                        }
                    }
                }
            }

            .pp_btn{
                margin-top: 5rem;
                display: flex;
                gap: 2rem;
                
                button{
                    padding: 0rem 2rem;
                    height: 5rem;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 1.8rem;
                    border-radius: 0.5rem;
                    background-color: white;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.3s;
                    border: 0.2rem solid transparent;
                    
                    &.create{
                        background-color: #01928d;
                        color: white;
                    }
    
    
                    &.cancel{
                        border: 0.1rem solid #01928d;
                    }
    
                    &:hover {
    
                        &.create{
                            background-color: #006e6b;
                        }
    
    
    
                        &.cancel{
                            background-color: rgb(245, 245, 245);
                        }
                    }
                }
            }
        }
    }
} 