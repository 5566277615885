@import "../../../common/variable.module.scss";

.support_edit {

    .support_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .checkIssue{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;
    
        .form_heading{
            position: relative;
            height: 4rem;
    
            h3{
                font-size: 2rem;
                font-weight: 600;
                text-align: center;
                line-height: 4rem;
                color: $theme-dark-color;
            }
    
            button{
                position: absolute;
                left: 0%;
                top: 0%;
                border: $back-button-border;
                background-color: $back-button-bg-color;
                height: 100%;
                padding: 0rem 2rem;
                border-radius: 0.5rem;
                font-size: $back-button-font-size;
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                box-shadow: $back-button-box-shadow;
                transition: all 0.2s;
    
                svg {
                    font-size: 2rem;
                }
    
                &:hover {
                    background-color: $back-button-hover-bg-color;
                }
            }
        }

        .edit_form{
            margin-top: 2rem;
            margin-bottom: 2%;
            
            .form_grid{
    
                .form_field{
                    display: flex;
                    flex-direction: column;
    
                    label{
                        font-size: 1.6rem;
                        font-weight: 500;
                        line-height: 4.5rem;
                        padding-top: 2rem;
                    }

                    textarea{
                        height: 20rem !important;
                        resize: none;
                        overflow: auto;
                        padding-top: 1.5rem !important;

                        &::-webkit-scrollbar{
                            width: 5px;
                            background-color: transparent;
                        }
                
                        &::-webkit-scrollbar-thumb{
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                            background-color: white;
                        }
                    }
    
                    textarea, select{
                        width: 100%;
                        height: 5rem;
                        border-radius: 0.5rem;
                        border: 1px solid #e0e0e0;
                        font-size: 1.6rem;
                        padding: 0rem 2rem;
                        outline: none;
                        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
    
                        &:focus{
                            outline: 0.1rem solid $theme-dark-color;
                        }
    
                    }
                }
            }
    
            .buttons{
                margin-top: 5rem;
                display: flex;
                gap: 2rem;
                
                button{
                    padding: 0rem 2rem;
                    height: 5rem;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 1.8rem;
                    border-radius: 0.5rem;
                    background-color: white;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.3s;
                    border: 0.2rem solid transparent;

                    svg{
                        animation: loading 2s ease infinite;
            
                        @keyframes loading {
                            0%{transform: rotate(0deg)}
                            100%{transform: rotate(360deg);}
                        }
                    }
                    
                    &.action{
                        background-color: $action-button-bg-color;
                        color: $action-button-color;
                    }
    
                    &.cancel{
                        border: $cancel-button-border;
                    }
    
                    &:hover {
    
                        &.action{
                            background-color: $action-button-hover-bg-color;
                        }
    
                        &.cancel{
                            background-color: $cancel-button-hover-bg-color;
                        }
                    }
                }
            }
        }
    }
}