@import "../../../common/variable.module.scss";

.language {
  .language_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .languageEdit {
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    min-height: 100%;
    padding: 4rem;

    .form_heading {
      position: relative;
      height: 4rem;

      h3 {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        line-height: 4rem;
        color: $theme-dark-color;
      }

      button {
        position: absolute;
        left: 0%;
        top: 0%;
        border: none;
        background-color: white;
        height: 100%;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .edit_form {
      margin-top: 2rem;
      margin-bottom: 2%;

      .form_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        .form_field {
          display: flex;
          flex-direction: column;

          label {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 4.5rem;
            // padding-top: 2rem;
          }

          input,
          select {
            width: 100%;
            height: 5rem;
            border-radius: 0.5rem;
            border: 1px solid #e0e0e0;
            font-size: 1.6rem;
            padding: 0rem 2rem;
            outline: none;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

            &:focus {
              outline: 0.1rem solid $theme-dark-color;
            }
          }

          .customFileInput {
            position: relative;
            width: 100%;

            .user_profile {
              width: 10rem;
              height: 10rem;
            }

            // input[type="file"] {
            //     opacity: 0;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     cursor: pointer;
            // }
          }

          .imagePreview {
            display: flex;
            align-items: center;

            label {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 15rem;
              height: 15rem;
              border: 1px solid #e0e0e0;
              border-radius: 0.5rem;
              overflow: hidden;
              box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              &:hover {
                border-color: #e74c3c;
              }
            }
          }
        }
      }

      .buttons {
        margin-top: 5rem;
        display: flex;
        gap: 2rem;

        button {
          padding: 0rem 2rem;
          height: 5rem;
          cursor: pointer;
          font-weight: 500;
          font-size: 1.8rem;
          border-radius: 0.5rem;
          background-color: white;
          box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
          transition: all 0.3s;
          border: 0.2rem solid transparent;

          svg {
            animation: loading 2s ease infinite;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }

          &.action {
            background-color: $action-button-bg-color;
            color: $action-button-color;
          }

          &.cancel {
            border: $cancel-button-border;
          }

          &:hover {
            &.action {
              background-color: $action-button-hover-bg-color;
            }

            &.cancel {
              background-color: $cancel-button-hover-bg-color;
            }
          }
        }
      }
    }
  }
}
