@import '../common/variable.module.scss';

.content{
    background-color: #eff3f6;
    width: calc(100% - $content-toggle-on-width-margin);
    margin-left: $content-toggle-on-width-margin;
    height: calc(100% - $navbar-height);
    overflow: auto;
    transition: all 0.3s;

    @media (max-width: 1280px) {
        width: 100%;
        margin-left: 0%;
    }

    &.active{
        width: calc(100% - $content-toggle-off-width-margin);
        margin-left: $content-toggle-off-width-margin;
    }

    &::-webkit-scrollbar{
        width: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: white;
    }

    .container{
        padding: 3rem 0rem;
        width: $container-width;
        margin: auto;
    }

    .audioPlayer{
        background-color: $theme-dark-color;
        position: fixed;
        bottom: 0%;
        height: 9rem;
        width: calc(100% - $content-toggle-on-width-margin);
        transition: all 0.3s;
        z-index: 100;

        &.active{
            width: calc(100% - $content-toggle-off-width-margin);
        }
    }
}