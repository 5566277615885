@import "../../../common/variable.module.scss";

.pp {

    .pp_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .pp_description{
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 4rem;

        .privacy_container{
            padding: 2rem;
            color: rgb(97, 97, 97);

            .toolbar {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 1rem;
        
                button, select {
                  margin-right: 1rem;
                  padding: 0.5rem;
                  border: 0.1rem solid #ddd;
                  background-color: #f5f5f5;
                  cursor: pointer;
                  border-radius: 0.4rem;
                  font-size: 1.4rem;
                }
        
                button {
                  font-weight: bold;
                }
            }
        
            .editor {
                min-height: 30rem;
                border: 0.1rem solid #ddd;
                padding: 1.5rem;
                border-radius: 0.4rem;
                font-family: 'Arial', sans-serif;
                font-size: 1.6rem;
                line-height: 1.5;
                outline: none;
        
                h2, h3, p {
                  margin: 1rem 0;
                }
        
                blockquote {
                  margin: 2rem;
                  padding: 1rem;
                  background-color: #f9f9f9;
                  border-left: 0.4rem solid #ccc;
                }
        
                pre {
                  background-color: #f5f5f5;
                  padding: 1rem;
                  border-radius: 0.4rem;
                  overflow-x: auto;
                }
            }

            .save_btn{
                // display: flex;
                // justify-content: start;
                margin-top: 2rem;

                button {
                    border: none;
                    background-color: white;
                    height: 4rem;
                    padding: 0rem 2rem;
                    border-radius: 0.5rem;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    cursor: pointer;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.2s;
        
                    svg {
                        font-size: 2rem;
                    }
        
                    &:hover {
                        background-color: $theme-dark-color;
                        background-color: rgb(233, 233, 233);
                    }
                }

                .unsavedChanges {
                    margin-left: 15rem;
                    color: red;
                    font-size: 1.4rem;
                    font-weight: bold;
                }
            }
        }
    }
}