@import "../common/variable.module.scss";

.paginationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;

    .showing {
        font-size: 1.5rem;
        font-weight: 500;

        select {
            margin-left: 0.5rem;
            padding: 0.3rem 0.5rem;
            border-radius: 0.25rem;
            border: none;
            background-color: #f2f2f2;
            margin-right: 0.5rem;
            outline: none;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
            padding: 0.8rem 1.5rem;
            border: none;
            border-radius: 0.5rem;
            background-color: #f2f2f2;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                background-color: #e5e5e5;
            }
        }

        .active {
            background-color: $active-pagination-background-color;
            color: white;
        }

        .dots {
            color: #878787;
            font-size: 1.6rem;
        }
    }
}