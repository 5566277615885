@import "../../../common/variable.module.scss";

.dashboard {
  .dashboard_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .tile_tracks {
    color: $theme-text-color;
    width: 100%;

    .tile {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 15rem;
      column-gap: 2rem;
      row-gap: 2rem;
      width: 100%;

      @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 15rem 15rem;
      }

      .tile_details {
        background-color: $box-bg-color;
        box-shadow: $box-shadow;
        border-radius: 1rem;
        padding: 2rem 2rem;
        position: relative;
        color: white;
        cursor: pointer;

        @media (max-width: 1024px) {
          height: 16rem;
          padding: 1rem 2rem;
        }

        &:nth-child(1) {
          background-image: linear-gradient(to right, #253a99, #2b5ceb);
        }

        &:nth-child(2) {
          background-image: linear-gradient(to right, #dd7200, #ff9e20);
        }

        &:nth-child(3) {
          background-image: linear-gradient(to right, #24a33f, #54c26c);
        }

        &:nth-child(4) {
          background-image: linear-gradient(to right, #b63f3f, #f56464);
        }

        &:nth-child(5) {
          background-image: linear-gradient(to right, #564dca, #706caf);
        }

        &:nth-child(6) {
          background-image: linear-gradient(to right, #1f7091, #35afe0);
        }

        h3 {
          font-size: 2rem;
          font-weight: 500;
          margin-top: 0;

          @media (max-width: 1024px) {
            font-size: 1.8rem;
          }
        }

        svg {
          position: absolute;
          font-size: 4.5rem;
          bottom: 30%;
          left: 8%;
          top: 55%;

          @media (max-width: 1024px) {
            font-size: 4rem;
          }
        }

        p {
          position: absolute;
          right: 10%;
          top: 70%;
          transform: translateY(-50%);
          font-size: 4.5rem;
          font-weight: 600;

          @media (max-width: 1024px) {
            font-size: 4rem;
            top: 40%;
          }
        }
      }
    }
  }

  .tile_tracks2 {
    color: $theme-text-color;
    width: 100%;
    margin-top: 2rem;

    .tile {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 15rem;
      column-gap: 2rem;
      row-gap: 2rem;
      width: 100%;

      @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 15rem 15rem;
      }

      .tile_details {
        // background-color: $box-bg-color;
        // background-color: #ddd2d2;
        background-color: #2521216e;
        // background-color: rgba(255, 255, 255, 0.15);
        box-shadow: $box-shadow;
        border-radius: 1rem;
        padding: 2rem 2rem;
        position: relative;
        color: #000000;
        // color: #272525;
        // color: white;
        cursor: pointer;

        @media (max-width: 1024px) {
          height: 16rem;
          padding: 1rem 2rem;
        }

        h3 {
          font-size: 2rem;
          font-weight: 500;
          margin-top: 0;

          @media (max-width: 1024px) {
            font-size: 1.8rem;
          }
        }

        svg {
          position: absolute;
          font-size: 4.5rem;
          bottom: 30%;
          left: 8%;
          top: 55%;

          @media (max-width: 1024px) {
            font-size: 4rem;
          }
        }

        p {
          position: absolute;
          right: 10%;
          top: 60%;
          transform: translateY(-50%);
          font-size: 4.5rem;
          font-weight: 600;

          @media (max-width: 1024px) {
            font-size: 4rem;
            top: 40%;
          }
        }
      }
    }
  }

  .sang {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-top: 3rem;

    .snag_des {
      display: flex;
      flex-direction: column;
      width: 100%;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
      }

      .view_all {
        a {
          text-decoration: none;
          color: #333;
          font-weight: 600;
        }
      }

      .cards {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 2rem;
        overflow: overlay;

        &::-webkit-scrollbar {
          display: none;
        }

        .dataNotFound {
          grid-column: 1/8;
          text-align: center;
          font-size: 1.8rem;
          color: #878787;
        }

        .card {
          width: 23rem;
          margin: 1rem;
          border-radius: 1rem;
          overflow: hidden;
          cursor: pointer;
          box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

          img {
            width: 100%;
            height: 20rem;
            object-fit: cover;
          }

          .card_content {
            padding: 1rem;

            h3 {
              font-size: 1.6rem;
              font-weight: 500;

              width: 20rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .song_count {
              display: flex;
              gap: 0.5rem;
              align-items: center;

              font-size: 1.4rem;
              color: #666666;
            }

            .artist_name {
              font-size: 1.4rem;
              color: #666666;
            }
          }
        }
      }
    }
  }

  .music_table {
    margin-top: 3rem;
    text-align: left;
    width: 100%;

    h2 {
      font-size: 2.5rem;
      font-weight: 600;
    }

    .table_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2rem;
      overflow: overlay;

      &::-webkit-scrollbar {
        display: none;
      }

      .dataNotFound {
        display: grid;
        grid-column: 3/8;
        color: #878787;
        font-size: 1.8rem;
        text-align: center;
        margin-top: 1rem;
      }

      .table_row {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        .song_description {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          column-gap: 2rem;

          border-bottom: 1px solid #d8d9d9;
          padding-bottom: 2rem;

          .song_image {
            img {
              width: 5rem;
              height: 5rem;
              border-radius: 0.5rem;
              object-fit: cover;
            }
          }

          .song_details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 22rem;

            h3 {
              font-size: 1.6rem;
              font-weight: 500;

              width: 20rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              font-size: 1.2rem;
            }
          }

          .song_count {
            display: flex;
            justify-content: start;
            gap: 0.8rem;
            align-items: center;

            svg,
            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
