@import "../../common/variable.module.scss";

.resetMain {
  background-color: $theme-dark-color;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .resetBg {
    background-color: $theme-light-color;
    min-width: 50rem;
    width: fit-content;
    padding: 3rem 4rem;
    border-radius: 1rem;

    @media (max-width: 1024px) {
      min-width: 45rem;
      padding: 3rem;
    }

    @media (max-width: 768px) {
      min-width: 35rem;
      padding: 2rem;
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 40%;

        @media (max-width: 1024px) {
          width: 35%;
        }

        @media (max-width: 768px) {
          width: 30%;
        }
      }

      h3 {
        font-size: 2.5rem;
        color: white;
        font-weight: 500;
        margin-top: 2rem;

        @media (max-width: 1024px) {
          font-size: 2.2rem;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      p {
        font-size: 1.5rem;
        max-width: 35rem;
        text-align: center;
        color: grey;
        margin-top: 0.5rem;

        @media (max-width: 1024px) {
          font-size: 1.4rem;
        }

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
    }

    .resetForm {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 3rem;

      @media (max-width: 1024px) {
        gap: 2.5rem;
        margin-top: 3.5rem;
      }

      @media (max-width: 768px) {
        gap: 2rem;
        margin-top: 3rem;
      }

      .inputField {
        div {
          display: flex;
          justify-content: center;
          gap: 2rem;

          input {
            width: 4rem;
            height: 4rem;
            padding: 0rem;
            text-align: center;

            @media (max-width: 1024px) {
              width: 3.8rem;
              height: 3.8rem;
            }

            @media (max-width: 768px) {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
        }

        div {
          position: relative;

          svg {
            position: absolute;
            font-size: 2rem;
            right: 1.5rem;
            color: white;
            cursor: pointer;
            z-index: 2;
            bottom: 50%;
            transform: translateY(80%);
          }
        }

        label {
          color: white;
          font-size: 1.8rem;

          @media (max-width: 1024px) {
            font-size: 1.6rem;
          }

          @media (max-width: 768px) {
            font-size: 1.4rem;
          }
        }

        input {
          margin-top: 1rem;
          font-size: 1.6rem;
          padding: 1.2rem 2rem;
          border: 0.1rem solid #939393;
          border-radius: 1rem;
          background-color: transparent;
          outline: none;
          width: 100%;
          color: white;

          @media (max-width: 1024px) {
            padding: 1rem 1.8rem;
            font-size: 1.5rem;
          }

          @media (max-width: 768px) {
            padding: 1rem 1.5rem;
            font-size: 1.4rem;
          }
        }

        p {
          color: red;
          margin-left: 1rem;
          margin-top: 1rem;
          font-size: 1.2rem;

          @media (max-width: 1024px) {
            font-size: 1.1rem;
            margin-left: 0.8rem;
          }

          @media (max-width: 768px) {
            font-size: 1rem;
            margin-left: 0.5rem;
          }
        }
      }

      .button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        @media (max-width: 1024px) {
          gap: 2.5rem;
        }

        @media (max-width: 768px) {
          gap: 2rem;
        }

        button {
          width: 100%;
          padding: 1rem 0rem;
          border: none;
          font-size: 1.8rem;
          font-weight: 600;
          border-radius: 1rem;
          background-color: white;
          cursor: pointer;

          @media (max-width: 1024px) {
            padding: 1.2rem 0;
            font-size: 1.7rem;
          }

          @media (max-width: 768px) {
            padding: 1rem 0;
            font-size: 1.6rem;
          }

          svg {
            animation: loading 2s ease infinite;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }

    .redirectLogin {
      margin-top: 1.5rem;
      text-align: center;
      display: flex;
      justify-content: center;

      a {
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        color: $theme-dark-color;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: white;
        text-decoration: none;

        @media (max-width: 1024px) {
          padding: 0.8rem 2.3rem;
          font-size: 1.3rem;
        }

        @media (max-width: 768px) {
          padding: 0.7rem 2rem;
          font-size: 1.2rem;
        }

        &:hover {
          svg {
            transform: translateX(-0.5rem);
          }
        }

        svg {
          font-size: 1.4rem;
          transition: transform 0.5s;

          @media (max-width: 1024px) {
            font-size: 1.3rem;
          }

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
